// Variables Declairation
$colorPrimary: #103170;
$colorSecondary: #9e9e9e;
$colorWhite: #ffffff;
$colorred: #da4141;
$colorgreen: #66ac65;
$appgreen: #02c8a7;

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.column-expand {
  // .col {
  //   &:nth-child(2) {
  //     flex-grow: 10;
  //   }
  // }
  .card-body {
    padding-bottom: 10px;
  }
}

.c-header-nav {
  .c-header-nav-item {
    &:first-child {
      a {
        // background: #ebedef;
        // border-radius: 100%;
        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }
}

.appleWatch {
  display: flex;
  align-items: center;
  justify-content: center;
}

.has-center-v-middle {
  display: flex;
  align-items: center;
  gap: 15px;
}

.check-no {
  background: url("../assets/images/light-arrow.jpg") no-repeat center center;
  width: 10px;
  height: 18px;
  text-indent: -9999px;
  transition: all 1s ease-in-out;
  transform: rotate(360deg);
}

.check-asc {
  background: url("../assets/images/asc-up-arrow.jpg") no-repeat center center;
  width: 10px;
  height: 18px;
  text-indent: -9999px;
  transition: all 1s ease-in-out;
  transform: rotate(0deg);
}

.check-desc {
  background: url("../assets/images/desc-down-arrow.jpg") no-repeat center
    center;
  width: 10px;
  height: 18px;
  text-indent: -9999px;
  transition: all 1s ease-in-out;
  transform: rotate(360deg);
}

// .noScroll {
//   overflow: hidden !important;
// }

.create-new-lib-popup {
  .image-thumb-list {
    padding-bottom: 0;
  }
  .card {
    margin: 0;
  }
  @media screen and (max-width: 1024px) {
    .card {
      margin-top: 30px;
      text-align: center;
    }
  }
}

.full-width {
  width: 100%;
}

.st-icon {
  width: 36px;
  cursor: pointer;
}

.card-height {
  height: calc(100% - 29px);
}

.m-0 {
  margin: 0;
}

.mt-40 {
  margin-top: 40px;
}

.mr-15 {
  margin-right: 15px !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.nowrap {
  white-space: nowrap;
}

.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}

/* ============== System Overwrites ============= */
.bg-primary {
  background: $colorPrimary !important;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $colorWhite;
  }
}

.btn {
  &:focus {
    box-shadow: none;
  }
}

.btn-link {
  color: $colorPrimary;
  text-decoration: none;
  &:hover {
    color: $colorPrimary;
  }
}

.btn-primary {
  background: $colorPrimary;
  &:hover {
    background: $colorPrimary;
    opacity: 0.9;
  }
  &:focus {
    background: $colorPrimary;
    &:active {
      background: rgba(16, 49, 112, 0.9) !important;
      border-color: transparent !important;
      box-shadow: none !important;
    }
  }
}

.page-login {
  .bg-primary {
    .btn-primary {
      background: $colorWhite;
      color: $colorPrimary;
    }
  }
}

.page-loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  border-radius: 50%;
  width: 46px;
  height: 46px;
  &.sm {
    width: 28px;
    height: 28px;
    border-width: 3px;
    border-top-color: #eee;
    border-right-color: #eee;
    border-bottom-color: #eee;
    -webkit-animation: load8 0.8s infinite linear;
    animation: load8 0.8s infinite linear;
  }
}

.form-control:focus {
  border-color: rgba(16, 49, 112, 0.5);
  box-shadow: none;
}

.page-item.active .page-link {
  background: $colorPrimary;
  border-color: $colorPrimary;
}

.btn-outline-primary {
  color: $colorPrimary;
  border-color: $colorPrimary;
  &:hover {
    background: $colorPrimary;
  }
}

.c-switch-opposite-dark .c-switch-input:checked + .c-switch-slider {
  border-color: $colorPrimary;
}
.c-switch-opposite-dark .c-switch-input:checked + .c-switch-slider::before {
  border-color: $colorPrimary;
  background: $colorPrimary;
}

/* ============== Generalize Styles ============= */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $colorPrimary;
}

// End Generalize
.logo-admin {
  img {
    max-width: 90px;
  }
}

.c-header {
  justify-content: flex-end;
}

.disabled {
  opacity: 0.5 !important;
  background: #ddd !important;
  cursor: not-allowed !important;
}

.c-header-nav-link {
  .badge-danger {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    padding: 0;
    margin-top: -14px !important;
    left: auto !important;
    right: 9px !important;
  }
}

.disabled-item {
  background: #ddd !important;
  cursor: not-allowed !important;
}

.disabled-cursor {
  cursor: not-allowed !important;
}

.page-loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  border-radius: 50%;
  width: 56px;
  height: 56px;
  &.sm {
    width: 28px;
    height: 28px;
    border-width: 3px;
    border-top-color: #eee;
    border-right-color: #eee;
    border-bottom-color: #eee;
    -webkit-animation: load8 0.8s infinite linear;
    animation: load8 0.8s infinite linear;
  }
}
.loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 6px solid rgba(255, 255, 255, 0.1);
  border-right: 6px solid rgba(255, 255, 255, 0.1);
  border-bottom: 6px solid rgba(255, 255, 255, 0.1);
  border-left: 6px solid #fff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.form-error-text {
  font-size: 0.85em;
  color: $colorred;
}

.only-logo-bar {
  background: #fff;
  p {
    margin: 0;
    padding: 8px 15px;
  }
  img {
    width: 40px;
  }
}

.icon-holder {
  flex: 0 0 56px;
  text-align: center;
  margin-left: -1rem;
  .sidebar-icon {
    width: 19px;
    filter: invert(0.7);
  }
}

.table-no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  min-height: 250px;
  color: #999;
  font-size: 1.18em;
  width: 98%;
}

/* ================ Login Card =============== */
.login-card {
  width: 100%;
  max-width: 480px;
  .field-error {
    display: block;
    color: $colorred;
    margin: 2px 0 0;
    font-size: 0.85em;
  }
  h1 {
    margin-top: -15px;
  }
}

.field-error {
  display: block;
  color: $colorred;
  margin: 2px 0 0;
  font-size: 0.85em;
}

.text-green {
  color: $colorgreen;
}
.text-red {
  color: $colorred;
}

.alert-login {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}

/* ================ Sidebar ============= */
.c-sidebar {
  background: $colorPrimary;
  .admin-sidebar-logo {
    width: 96px;
    margin: 5px;
  }
  a {
    &.c-sidebar-nav-link {
      &:hover {
        background: rgba(255, 255, 255, 0.05);
        .icon-holder {
          img {
            filter: invert(1);
          }
        }
      }
      &.c-active {
        background: rgba(255, 255, 255, 0.15);
        .icon-holder {
          img {
            filter: invert(1);
          }
        }
      }
    }
  }
}

/* ============== Table Listing ============= */
table.table {
  tr {
    th {
      font-weight: 600;
      border-top: 0;
      &:last-child {
        min-width: 85px;
      }
    }
    td {
      border-color: #e6e6e6;
      &.has-action-icons {
        svg,
        .img-icon {
          margin: 0 5px;
          width: 0.95em;
          height: 0.95em;
          color: #294c8e;
          transition: 0.15s;
          &.icon-trash {
            color: $colorred;
          }
          &:hover {
            transform: scale(1.2);
          }
        }
        .img-icon {
          width: 1rem;
          height: auto;
          opacity: 0.65;
        }
      }
      .badge {
        border: 1px solid #ddd;
        background: #efefef;
        border-radius: 3px;
        font-weight: 500;
        padding: 4px 8px 6px;
        margin: -2px 0;
        min-width: 56px;
        &.badge-success {
          background: $colorgreen;
          border-color: $colorgreen;
        }
      }
      .multi-icons {
        display: flex;
        align-items: center;
        svg {
          margin: 0 10px;
        }
      }
    }
  }
  &.table-striped {
    tbody {
      tr {
        &:nth-of-type(odd) {
          background: #fafafa;
        }
      }
    }
  }
  &.table-hover {
    tbody {
      tr {
        &:hover {
          background: rgba(16, 49, 112, 0.04);
        }
      }
    }
  }
}

.has-action-icons {
  .multi-icons {
    svg {
      cursor: pointer;
    }
  }
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border-color: #e6e6e6;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #fafafa;
}

/* ============ Page Header ============ */
.page-title {
  margin: 0;
}
.search-n-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  button {
    margin: 0 0 0 10px;
    flex: 0 0 auto;
  }
  input {
    max-width: 230px;
    background: url("../assets/images/search-icon.png") no-repeat left 10px
      center;
    padding-left: 40px;
    background-size: 7%;
  }
}

.btn-primary-secondary {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
  }
}

/* ============ Edit  =========== */
.footer-has-primary-secondary-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    margin-right: 12px;
    min-width: 80px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.rights-header {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
  background: #fcfcfc;
  label {
    margin: 2px 0 !important;
  }
}

.switcher-list {
  label {
    margin: 4px 0;
  }
}

.has-switch {
  display: flex;
  align-items: center;
}

/* ============ Set Password ============ */
.has-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.set-pass-card {
  max-width: 520px;
  margin: 24px;
  h5 {
    margin: 0;
  }
}

.alert-setpass {
  max-width: 440px;
  margin: 0 auto;
}

/* ================ image library =============== */
.check-filters {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  .filter-items {
    margin: 0 5px 10px 0;
    width: auto;
    font-size: 0.85em;
    font-weight: 500;
    color: #fff;
    position: relative;
    input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      opacity: 0;
      cursor: pointer;
      &:checked {
        & + span {
          background: $appgreen;
          color: #fff;
        }
      }
      &:hover {
        & + span {
          cursor: pointer;
        }
      }
    }
    span {
      background: #fff;
      padding: 7px 10px;
      text-align: center;
      border-radius: 50px;
      display: block;
      box-shadow: 0 0 0 1.5px $appgreen inset;
      color: #00a68a;
    }
  }
}

.round-check-filters {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .check-items {
    margin: 15px 5px 0 0;
    font-size: 0.85em;
    font-weight: 500;
    color: #103170;
    position: relative;
    display: flex;
    align-items: center;
    input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      opacity: 0;
      &:checked {
        & + span {
          background: #103170;
          color: #fff;
        }
      }
      &:hover {
        & + span {
          cursor: pointer;
        }
      }
    }
    span {
      color: #103170;
      border: solid 1px #103170;
      width: 30px;
      height: 30px;
      display: inline-flex;
      border-radius: 100%;
      text-align: center;
      margin-right: 10px;
      .c-check-icon {
        flex: 0 0 30px;
        height: 1.09375rem;
        font-size: 1.09375rem;
        margin-top: 5px;
      }
    }
  }
}

.image-thumb-list {
  display: flex;
  flex-wrap: wrap;
  padding-right: 0;
  .scrollDiv {
    overflow-y: auto;
    height: calc(760px - 130px);
    width: 100%;
  }
  .scrollDiv2 {
    overflow-y: auto;
    height: 299px;
    width: 100%;
  }
  @media screen and (max-width: 1366px) {
    .scrollDiv {
      height: calc(450px - 130px);
    }
  }
  .loader {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    border-left-color: #103170;
  }
  figure {
    margin: 0 16px 15px 0;
    width: 140px;
    height: 96px;
    position: relative;
    background: rgb(237, 237, 237);
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 8px;
    img {
      width: 67px;
      height: 67px;
      object-fit: cover;
    }
    span {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 1.1em;
      font-weight: 500;
      background: rgba(52, 60, 68, 0.8);
      padding: 15px;
      line-height: 1.25;
      text-align: center;
      transition: 0.15s;
      opacity: 0;
      cursor: pointer;
      border-radius: 8px;
      span {
        &.conText {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          height: 34px;
          background: none;
          opacity: 1;
          margin-top: 25px;
        }
      }
      &:hover {
        opacity: 1;
      }
    }
  }
  @media screen and (max-width: 1366px) {
    figure {
      margin-right: 10px;
      width: 102px;
    }
  }
}

/* Card */

.card {
  .card-body {
    .user-image {
      max-width: 60px;
      flex-basis: 0;
      flex-grow: 1;
      min-width: 0;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      .subtask-up {
        .field-error {
          width: 49px;
          padding-bottom: 30px;
        }
        // .upload-photo {
        //   width: 36px;
        //   height: 36px;
        // }
      }
    }
    .profile-image-section {
      figure {
        position: relative;
      }
      label {
        &.field-error {
          text-align: center;
          margin-bottom: 10px;
        }
      }
      img {
        width: 100% !important;
        height: 326px;
        object-fit: contain;
        &.d-placeholder {
          position: absolute;
          width: 100px !important;
        }
        &.imgLight {
          filter: contrast(0.5);
          opacity: 0.4;
        }
      }
    }
    .secDisabled,
    .secDisabled input {
      cursor: not-allowed !important;
    }
    .loader {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      border-left-color: #103170;
    }
  }
}

.colorChanger {
  display: flex;
  gap: 10px;
  .cc-oval {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: 2px solid transparent;
    cursor: pointer;
  }
  .cc-oval-selected {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: 2px solid black;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      width: 44px;
      height: 44px;
      position: relative;
      border-radius: 100%;
      border: solid 3px #ffffff;
      left: 0;
      top: 0;
    }
  }
}

.user-image {
  .c-avatar-img {
    height: 60px;
    border-radius: 100%;
    object-fit: cover;
    cursor: pointer;
  }
}

.c-avatar {
  .loader {
    position: absolute !important;
    left: 0;
    top: 0;
    margin: 0;
    border-left: 6px solid #103170;
    width: 100%;
    height: 100%;
  }
  .c-avatar-img {
    width: 36px !important;
    height: 36px;
    object-fit: cover;
  }
}

.upload-photo {
  text-align: center;
  position: relative;
  .upload-btn {
    border: solid 1px #103170;
    color: #103170;
    padding: 8px 30px;
    &:hover {
      background: #103170;
      color: #ffffff;
    }
  }
  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 183px;
    height: 100%;
    margin: 0 auto;
    opacity: 0;
    cursor: pointer;
    right: 0;
  }
}

.imgCropSlider {
  margin-top: 30px;
  margin-bottom: 30px;
  input {
    width: 100%;
  }
}

.confirmPopup {
  h2 {
    font-size: 1.4em;
  }
}

.loadmore-imgLib {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  button {
    background: #103170;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    padding: 7px 30px;
    border-radius: 50px;
    display: block;
    &:hover {
      background: #000000;
      color: #ffffff;
    }
  }
}

.subTask-sec {
  .col:nth-child(2) {
    flex-grow: 3;
  }
  h6 {
    margin: 0;
    margin-top: 7px;
  }
}

@media (max-width: 767px) {
  .image-thumb-list figure {
    margin-right: 0;
    width: 100%;
  }

  .search-n-btn input {
    max-width: 100%;
  }
}

th {
  white-space: nowrap;
}

.imgCropContainer {
  height: 200px;
  position: relative;
}
@media screen and (max-width: 1366px) {
  .imgCropContainer {
    height: 385px;
  }
}

.imgLibPlaceHolder {
  width: 60%;
  margin: 0 auto 30px auto;
  opacity: 0.4;
  display: flex;
}

.payments-section {
  table {
    tr {
      td {
        cursor: pointer;
      }
    }
  }
}

.notifications {
  width: 30.5rem;
  overflow: hidden;
  .notify-title {
    position: relative;
    .c-avatar-status {
      top: 0;
      &.bg-danger {
        width: 10px;
        height: 10px;
      }
    }
  }
  h2 {
    font-size: 1.1rem;
  }
  h3 {
    font-size: 0.8rem;
  }
  .dropdown-item {
    &:hover {
      background: none !important;
    }
    .message {
      width: 100%;
      display: flex;
      .notify-text {
        font-size: 0.7rem;
        white-space: normal;
      }
      .just-now {
        small {
          font-size: 0.6rem;
          color: #103170 !important;
          font-weight: 700;
          text-transform: capitalize;
        }
      }
    }
  }
}

.notificationsScroll {
  height: 480px;
  overflow-y: auto;
  .dropdown-item {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: solid 1px #d1d1d1;
  }
}

.btn {
  &.viewButton {
    margin: 0;
    padding: 0;
    background: #103170;
    color: #ffffff;
    padding: 7px 20px;
  }
}

.payment-history {
  table {
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}

.download-btn {
  filter: invert(1);
  margin: 0 !important;
  padding: 0 !important;
  width: 23px;
  height: 26px;
}

.hide-button {
  display: none;
}

.ml-1rem {
  margin-left: 1rem;
}

.mr-1rem {
  margin-right: 1rem;
}

// .fa-exclamation {
//   width: 10px;
//   height: 10px;
//   position: absolute;
//   right: 0;
//   top: 0;
//   background: red;
//   border-radius: 100%;
// }

.nobgoclor {
  background: none !important;
  opacity: 1 !important;
}

.ImageLib-Select-dropdown {
  display: flex;
  margin: 0 0 10px 0;
  button {
    border: 1px solid #e0e0e0;
    padding: 7px 15px 6px 15px;
    background: none;    
    border-radius: 9px;
    font-size: 15.2px;
    color: #A2A1A1;
    font-family: arial, sans-serif;
    box-sizing: border-box;
    text-align: left;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0;
    > div {
      width: 100% !important;
      display: flex;
      align-items: center;
      font-family: arial, sans-serif;
      div {
        margin: 1px 6px 0 0;
        width: 18px !important;
        height: 18px !important;
        border: solid 1px #CCCCCC;
        font-family: arial, sans-serif;
      }
    }
    svg {
      font-size: 24px;
      margin: 0;
      color: rgba(0, 0, 0, 0.54);
    }
    &:focus {
      outline: none;
    }
  }
  .circle-picker {
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.25);
    background: #FFFFFF;
    border-radius: 14px;
    margin: 44px 0 0 0;
    align-items: center;
    justify-content: center;
    padding: 15px 15px;
    width: auto !important;
    @media screen and (max-width: 1366px) {
      width: auto !important;
      // padding-right: 20px;
    }
    span {
      &:first-child {
        div {
          div {
            border: solid 1px #CCCCCC;
            border-radius: 100% !important;
          }
        }
      }
      div {
        width: 23px !important;
        height: 23px !important;
        margin: 0 13px 0 0 !important;
        @media screen and (max-width: 1366px) {
          width: 18px !important;
          height: 18px !important;
          // margin: 14px 6px !important;
        }
      }
    }
  }
}